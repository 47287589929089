/* eslint-disable no-undef */
declare const __APIUrl__: string;
declare const __ADDINVersion__: string;

let accessTokenPromise: Promise<string> | null = null;

const getAccessToken = (): Promise<string> => {
  if (accessTokenPromise) {
    return accessTokenPromise;
  }

  accessTokenPromise = OfficeRuntime.auth
    .getAccessToken()
    .then((token) => {
      accessTokenPromise = null;
      return token;
    })
    .catch((error) => {
      accessTokenPromise = null;
      throw error;
    });

  return accessTokenPromise;
};

Office.onReady(async () => {
  try {
    const userTokenEncoded = await getAccessToken();

    const userEmail = Office.context.mailbox.userProfile.emailAddress;

    await pingAddinStatus(userTokenEncoded, userEmail);
  } catch (error) {
    console.error("Erreur lors du chargement de l'addin :", error);
  }
});

const pingAddinStatus = async (token: string, email: string): Promise<void> => {
  const apiUrl = `${__APIUrl__}/connect/azure/check?ping=true&code=${token}&email=${encodeURIComponent(email)}`;

  try {
    const response = await fetch(apiUrl, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Addin-Version": __ADDINVersion__,
      },
    });

    if (!response.ok) {
      console.error("Erreur lors de la vérification du statut de l'addin.");
      return;
    }

    const result = await response.json();
    console.log("Statut du ping :", result);
  } catch (error) {
    console.error("Erreur lors du ping à l'API :", error);
  }
};

const signallyNotificationId: string = "Signally.Notification";

const getTypeSignature = (composeType: string, isInternal: boolean): string => {
  console.log("Compose Type reçu dans getTypeSignature:", composeType);
  console.log("Is Internal:", isInternal);

  if (isInternal) return "internSignature";
  if (composeType === Office.MailboxEnums.ComposeType.Reply) return "replySignature";
  if (composeType === Office.MailboxEnums.ComposeType.Forward) return "forwardSignature";
  return "publicSignature";
};

const getComposeType = (): Promise<{ composeType: string; coercionType: string }> => {
  return new Promise((resolve, reject) => {
    Office.context.mailbox.item.getComposeTypeAsync((asyncResult) => {
      if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
        console.log("Compose Type récupéré:", asyncResult.value);
        resolve(asyncResult.value);
      } else {
        reject(asyncResult.error);
      }
    });
  });
};

const getSignature = async (
  token: string,
  signatureType: string,
  userEmail: string,
  isAutorun: boolean
): Promise<string | null> => {
  let apiUrl = `${__APIUrl__}/connect/azure/check?code=${token}&email=${encodeURIComponent(userEmail)}&autorun=${isAutorun}`;

  try {
    const response = await fetch(apiUrl, {
      method: "GET",
      headers: {
        "Addin-Version": __ADDINVersion__,
      },
    });
    if (!response.ok) {
      return signallyAPICallErrors(response);
    }
    const json = await response.json();
    console.log("Signature récupérée pour le type:", signatureType);
    return json[signatureType];
  } catch (error) {
    console.error(error);
    sendNotification(error);
    return null;
  }
};

const getDomainFromEmail = (email: string): string => {
  return email.substring(email.lastIndexOf("@") + 1).toLowerCase();
};

const isInternalRecipient = async (): Promise<boolean> => {
  return new Promise((resolve, reject) => {
    Office.context.mailbox.item.from.getAsync((fromResult) => {
      if (fromResult.status === Office.AsyncResultStatus.Succeeded) {
        const senderEmail = fromResult.value.emailAddress;
        const senderDomain = getDomainFromEmail(senderEmail);

        Office.context.mailbox.item.to.getAsync((toResult) => {
          if (toResult.status === Office.AsyncResultStatus.Succeeded) {
            const recipients = toResult.value as Office.EmailAddressDetails[];

            if (recipients.length === 0) {
              console.log("Aucun destinataire présent.");
              resolve(false);
              return;
            }

            const isInternal = recipients.every(
              (recipient) => getDomainFromEmail(recipient.emailAddress) === senderDomain
            );
            resolve(isInternal);
          } else {
            reject(toResult.error);
          }
        });
      } else {
        reject(fromResult.error);
      }
    });
  });
};

// Fonction pour l'événement autorun
const autorunAction = async (event: Office.AddinCommands.Event) => {
  console.log("Exécution autorun détectée.");
  await handleAction(event, true); // Passe true pour indiquer que c'est un autorun
};

// Fonction pour l'action utilisateur
const userAction = async (event: Office.AddinCommands.Event) => {
  console.log("Action utilisateur détectée.");
  await handleAction(event, false); // Passe false pour indiquer que c'est un clic utilisateur
};

const handleAction = async (event: Office.AddinCommands.Event, isAutorun: boolean) => {
  let version = Office.context.mailbox.diagnostics.hostName;
  if (version.toLowerCase() === "outlook") {
    console.log("outlook");
  }

  let userTokenEncoded;
  let composeTypeObj;
  let isInternal = false;
  let userEmail = "";

  try {
    userTokenEncoded = await getAccessToken();

    userEmail = Office.context.mailbox.userProfile.emailAddress;

    composeTypeObj = await getComposeType();
    console.log("Compose Type après récupération:", composeTypeObj.composeType);

    isInternal = await isInternalRecipient();
  } catch (error) {
    console.error(error);
    event.completed();
    return;
  }

  let signatureType = getTypeSignature(composeTypeObj.composeType, isInternal);
  console.log("Type de signature détecté:", signatureType);

  let signature = await getSignature(userTokenEncoded, signatureType, userEmail, isAutorun);

  if (signature && signature.trim().length > 10) {
    Office.context.mailbox.item.disableClientSignatureAsync((asyncResult) => {
      if (asyncResult.status !== Office.AsyncResultStatus.Succeeded) {
        console.error(asyncResult.error);
      }
    });

    Office.context.mailbox.item.notificationMessages.removeAsync(signallyNotificationId);

    Office.context.mailbox.item.body.setSignatureAsync(
      signature,
      {
        coercionType: "html",
        asyncContext: event,
      },
      function (asyncResult) {
        asyncResult.asyncContext.completed();
      }
    );
  } else {
    console.log("Aucune signature appliquée ou signature trop courte.");
  }
  event.completed();
};

const sendNotification = (error: any) => {
  let errorMessage = error.code
    ? error.message
    : error.message
      ? error.message
      : error.detail
        ? error.detail
        : error.toString();
  let message: Office.NotificationMessageDetails = {
    type: Office.MailboxEnums.ItemNotificationMessageType.InformationalMessage,
    message: `${errorMessage.substring(0, 150)}`,
    icon: "Icon.80x80",
    persistent: true,
  };
  Office.context.mailbox.item.notificationMessages.replaceAsync(signallyNotificationId, message);
};

const signallyAPICallErrors = (response: Response): Promise<never> => {
  return response.text().then((text) => {
    let errorMessage;
    if (response.status === 404 || response.status === 403) {
      errorMessage = JSON.stringify({ code: response.status, message: JSON.parse(text) });
    } else {
      errorMessage = text;
    }
    throw new Error(errorMessage);
  });
};

const getGlobal = () => {
  return typeof self !== "undefined"
    ? self
    : typeof window !== "undefined"
      ? window
      : typeof global !== "undefined"
        ? global
        : undefined;
};

const g = getGlobal() as any;
g.autorunAction = autorunAction;
g.userAction = userAction;
//@ts-ignore
Office.actions.associate("autorunAction", autorunAction);
//@ts-ignore
Office.actions.associate("userAction", userAction);

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function reject(_arg0: null) {
  throw new Error("Function not implemented.");
}
